







































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({ components: {} })
export default class DailyCommissionedTable extends Vue {
    @Prop() contractors;
    @Prop() noData;
    @Prop() message;
    @Prop() device_types;

    rows_color = ['#F8CCD3', '#FBE7EA'];
    headers = ['Contractor', 'Area', 'Device Type', 'Installed', 'Active'];
    tableData = [];
    loading = true;

    mounted(){
        this.loading = true;
        if (!this.noData) this.generateTableData();
        this.loading = false;
    }

    generateTableData(){
        Object.values(this.contractors).forEach((contractor) => {
            Object.entries(this.device_types).forEach(([type, title], index) => {
                this.tableData.push({
                    contractor_name: contractor['contractor_name'], 
                    area: contractor['area'].join(), 
                    type: title, 
                    commissioned: contractor[type].active + contractor[type].inactive, 
                    active: contractor[type].active 
                }); 
            });
        });
    }

}
