






















import { Reports, User, Global, Props, Users } from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import PropUtils from '@/modules/PropUtils';
import moment from 'moment-timezone';
import Utils from '@/modules/Utils';
import DailyCommissionedTable from '@/pages/reports/components/DailyCommissionedTable.vue';
import FullViewReportBar from '@/pages/fullViewReport/components/reportBar/FullViewReportBar.vue';
import API, { Types } from '@/modules/API';

@Component({
  components: {
    DailyCommissionedTable,
    FullViewReportBar
  }
})
export default class DailyCommissioned extends Vue {

  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;
  @User.State('project') project;
  @Reports.Getter('commissioned_map') commissioned_map;
  @Props.State('list') projectProperties;
  @Users.State('list') users;
  
  contractors = null;
  commissioners = new Map();
  device_types = {
    fixture: 'Tondo Device',
    cabinet: 'Cabinet Monitor'
  };
  loading = true;
  noData = false;
  today_timestamp = null;
  yesterday_timestamp = null;
  commissioned_today = [];
  weekly_commissioned = [];
  WeeklyCommissionedName = ['', '', '', '', '', '', ''];
  barData = {};
  START_TIME = '06:00:00';
  END_TIME = '06:00:00';
  message = '';
  default_contractor = 'Unknown';
  default_commissioner = 'unknown';

  async mounted() {
    this.loading = true;
    this.noData = false;

    // this.setContractorsProperty();
    this.setThresholdProperty();
    this.setDates();
    this.getWeeklyCommissioned();

    // if (this.contractors && Object.keys(this.contractors).length) {
    //   this.initializeCommissioners();
    //   this.initializeDeviceTypes();
    //   await this.generateDataByContractor();
    // } else {
    //   this.noData = true;
    //   this.message = 'No Data Available';
    //   console.log('There is no project.contractors project property');
    // }

    this.loading = false;
  }

  setContractorsProperty() {
    const contractors_property = PropUtils.getProperty(
      this.projectProperties,
      'project.contractors'
    );
    this.contractors = contractors_property ? contractors_property.value : null;
    if (!this.contractors || !Object.keys(this.contractors).length){
      this.contractors = {};
    }
    this.contractors[this.default_contractor] = {
      contractor_name: this.default_contractor,
      area: [this.default_contractor], 
      email: this.default_contractor,
      phone: '',
      commissioners: [this.default_commissioner]
    };
  }

  setThresholdProperty() {
    const thresholdProperty = PropUtils.getProperty(this.projectProperties, 'meta.events_threshold');
    if (thresholdProperty && thresholdProperty.value){
      if (thresholdProperty.value.commissionedStart && thresholdProperty.value.commissionedEnd) {
        this.START_TIME = thresholdProperty.value.commissionedStart;
        this.END_TIME = thresholdProperty.value.commissionedEnd;
      }
    }
  }

  setDates() {
    this.today_timestamp = moment(
      `${moment.tz(this.projectTimezone).format('YYYY-MM-DD')} ${this.END_TIME}`
    ).valueOf();
    this.yesterday_timestamp = moment(
      `${moment
        .tz(this.projectTimezone)
        .subtract(1, 'days')
        .format('YYYY-MM-DD')} ${this.START_TIME}`
    ).valueOf();

    let days = 7;

    this.WeeklyCommissionedName.forEach((name, index) => {
      this.WeeklyCommissionedName[index] = moment(moment().subtract(days, 'days').valueOf()).tz(this.projectTimezone).format('DD/MM/YYYY');
      --days;
    });
  }

  initializeCommissioners() {
    Object.values(this.contractors).forEach((contractor) => {
      contractor['commissioners'].forEach((commissioner) => {
        this.commissioners.set(commissioner.toLowerCase(), contractor['email']);
      });
    });
  }

  initializeDeviceTypes() {
    Object.values(this.contractors).forEach((contractor) => {
      Object.keys(this.device_types).forEach((type) => {
        contractor[type] = { active: 0, inactive: 0 };
      });
    });
  }

  async generateDataByContractor() {
    this.getCommissionedToday();
    this.getWeeklyCommissioned();
    this.commissioned_today.length
      ? await this.dividDevicesByStatus()
      : (this.noData = true);
    if (this.noData) this.message = 'No devices were installed yesterday';
  }

  getCommissionedToday() {
    [...this.commissioned_map.values()].forEach((device) => {
      const commission_datetime = Utils.convertCommissionDate(device['meta.commission'].date, 'YYYY-MM-DD HH:mm:ss');
      const commission_timestamp = commission_datetime && moment(commission_datetime).valueOf();
      const timediff = this.today_timestamp - commission_timestamp;
      if (commission_timestamp && commission_timestamp < this.today_timestamp && timediff < 86400000) {
        this.commissioned_today.push(device.id);
      }
    });
  }

  getWeeklyCommissioned() {
    const weeklyCommissioned = {fixture: [0, 0, 0, 0, 0, 0, 0], cabinet: [0, 0, 0, 0, 0, 0, 0]};
    [...this.commissioned_map.values()].forEach((device) => {
      const commission_datetime = Utils.convertCommissionDate(device['meta.commission'].date, 'YYYY-MM-DD HH:mm:ss');
      const commission_timestamp = commission_datetime && moment(commission_datetime).valueOf();
      if (
        commission_timestamp && commission_timestamp < this.today_timestamp &&
        commission_timestamp > this.today_timestamp - 86400000 * 7
      ) {
        this.updateDayCounter(device, commission_timestamp, weeklyCommissioned);
      }
    });
    this.barData = {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: this.WeeklyCommissionedName
      },
      yaxis: {
        labels: {
          formatter: (val) => val.toFixed(0)
        },
        decimalsInFloat: 0
      }
    };
    this.weekly_commissioned = [{ name: 'Tondo Device', data: weeklyCommissioned.fixture }, { name: 'Cabinet Monitor', data: weeklyCommissioned.cabinet }];    
  }

  updateDayCounter(device, commission_timestamp, weeklyCommissioned){
    const timediff = this.today_timestamp - commission_timestamp;
    const type = Utils.getDeviceType(device.class_name);
    if (timediff < 86400000) {
      weeklyCommissioned[type][6]++;
    } else if (timediff < 86400000 * 2) {
      weeklyCommissioned[type][5]++;
    } else if (timediff < 86400000 * 3) {
      weeklyCommissioned[type][4]++;
    } else if (timediff < 86400000 * 4) {
      weeklyCommissioned[type][3]++;
    } else if (timediff < 86400000 * 5) {
      weeklyCommissioned[type][2]++;
    } else if (timediff < 86400000 * 6) {
      weeklyCommissioned[type][1]++;
    } else if (timediff < 86400000 * 7) {
      weeklyCommissioned[type][0]++;
    }
  }

  checkDateInRange(date, start_date, end_date) {
    return (
      Utils.calculateDateDifference(start_date, date, 'seconds') >= 0 &&
      Utils.calculateDateDifference(date, end_date, 'seconds') >= 0
    );
  }

  async dividDevicesByStatus() {
    const slices = Math.ceil(this.commissioned_today.length / 100);
    let startIndex = 0,
      endIndex = Math.min(this.commissioned_today.length, 100);
    for (let i = 0; i < slices; i++) {
      const current = this.commissioned_today.slice(startIndex, endIndex);
      await Promise.all(
        current.map((device_id) =>
          this.setStatus(
            device_id,
            this.yesterday_timestamp,
            this.today_timestamp
          )
        )
      );

      startIndex = endIndex;
      endIndex = Math.min(this.commissioned_today.length, endIndex + 100);
    }
  }

  async setStatus(device_id, from, to) {
    const device = this.commissioned_map.get(device_id);

    const sys___active = await API.get(
      Types.DEVICES,
      device_id + '/streams/' + device_id + '.sys___active/history',
      { from, to }
    );

    const contractor = this.commissioners.get(device['meta.commission'].commissioner.toLowerCase()) || this.commissioners.get(this.default_commissioner);
    const type = Utils.getDeviceType(device.class_name);
    if (contractor && this.contractors[contractor][type]) {
      if (sys___active && sys___active.length) {
        const isActive = sys___active.some(
          (stream_history) => stream_history[1] === 1
        );

        isActive
          ? ++this.contractors[contractor][type].active
          : ++this.contractors[contractor][type].inactive;
      } else {
        ++this.contractors[contractor][type].inactive;
      }
    }
  }
}
