<template>
  <div id="chart" style="width: 100%">
    <v-layout justify-center>
      <h2>{{$t("Weekly Devices Installation")}}</h2>
    </v-layout>
    <VueApexCharts type="bar" height="350" width="100%" :options="barData" :series="weeklyDevices"></VueApexCharts>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'FullViewReportBar',
  components:  {VueApexCharts} ,
  props: ['weeklyDevices', 'barData'],
  data() {
    return {
    };
  },
  methods: {
  }
};

</script>